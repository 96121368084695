// extracted by mini-css-extract-plugin
export var blue = "tag-module--blue--5SbGL";
export var cyan = "tag-module--cyan--4CvVv";
export var geekblue = "tag-module--geekblue--fHh9o";
export var gold = "tag-module--gold--1zYer";
export var green = "tag-module--green--jtnxH";
export var lime = "tag-module--lime--7VmTz";
export var magenta = "tag-module--magenta--r4LoM";
export var orange = "tag-module--orange--FeHPd";
export var purple = "tag-module--purple--oKVtp";
export var red = "tag-module--red--ZILa2";
export var rsBadgeBlue = "tag-module--rs-badge-blue--oevRN";
export var rsBadgeContent = "tag-module--rs-badge-content--Qjvq-";
export var rsBadgeCyan = "tag-module--rs-badge-cyan--HX96C";
export var rsBadgeGreen = "tag-module--rs-badge-green--25g70";
export var rsBadgeIndependent = "tag-module--rs-badge-independent--5f+4l";
export var rsBadgeOrange = "tag-module--rs-badge-orange--9xc7r";
export var rsBadgeRed = "tag-module--rs-badge-red--EX4tN";
export var rsBadgeViolet = "tag-module--rs-badge-violet--wpV1D";
export var rsBadgeYellow = "tag-module--rs-badge-yellow--w9uNM";
export var rsBtnBlue = "tag-module--rs-btn-blue--Cn-qC";
export var rsBtnCyan = "tag-module--rs-btn-cyan--tfujq";
export var rsBtnGreen = "tag-module--rs-btn-green--SYUlJ";
export var rsBtnOrange = "tag-module--rs-btn-orange--gfQPD";
export var rsBtnRed = "tag-module--rs-btn-red--i37D6";
export var rsBtnViolet = "tag-module--rs-btn-violet--sb7zD";
export var rsBtnYellow = "tag-module--rs-btn-yellow--wlNSY";
export var rsFlexBoxGridItem1 = "tag-module--rs-flex-box-grid-item-1--pNIkG";
export var rsFlexBoxGridItem10 = "tag-module--rs-flex-box-grid-item-10--SPk5M";
export var rsFlexBoxGridItem11 = "tag-module--rs-flex-box-grid-item-11--w1yCK";
export var rsFlexBoxGridItem12 = "tag-module--rs-flex-box-grid-item-12--4E5RG";
export var rsFlexBoxGridItem13 = "tag-module--rs-flex-box-grid-item-13--qdSF+";
export var rsFlexBoxGridItem14 = "tag-module--rs-flex-box-grid-item-14--nabY4";
export var rsFlexBoxGridItem15 = "tag-module--rs-flex-box-grid-item-15--O2hdP";
export var rsFlexBoxGridItem16 = "tag-module--rs-flex-box-grid-item-16--c-Nnw";
export var rsFlexBoxGridItem17 = "tag-module--rs-flex-box-grid-item-17--ULH9D";
export var rsFlexBoxGridItem18 = "tag-module--rs-flex-box-grid-item-18--XJkuJ";
export var rsFlexBoxGridItem19 = "tag-module--rs-flex-box-grid-item-19--W619w";
export var rsFlexBoxGridItem2 = "tag-module--rs-flex-box-grid-item-2--3a7sO";
export var rsFlexBoxGridItem20 = "tag-module--rs-flex-box-grid-item-20--yeCE4";
export var rsFlexBoxGridItem21 = "tag-module--rs-flex-box-grid-item-21--0ilNX";
export var rsFlexBoxGridItem22 = "tag-module--rs-flex-box-grid-item-22--vwCD9";
export var rsFlexBoxGridItem23 = "tag-module--rs-flex-box-grid-item-23--xWSLn";
export var rsFlexBoxGridItem24 = "tag-module--rs-flex-box-grid-item-24--O1+vz";
export var rsFlexBoxGridItem3 = "tag-module--rs-flex-box-grid-item-3--sG8wh";
export var rsFlexBoxGridItem4 = "tag-module--rs-flex-box-grid-item-4--51XSl";
export var rsFlexBoxGridItem5 = "tag-module--rs-flex-box-grid-item-5--VRuqu";
export var rsFlexBoxGridItem6 = "tag-module--rs-flex-box-grid-item-6--F13Qw";
export var rsFlexBoxGridItem7 = "tag-module--rs-flex-box-grid-item-7--3plxU";
export var rsFlexBoxGridItem8 = "tag-module--rs-flex-box-grid-item-8--dMnbM";
export var rsFlexBoxGridItem9 = "tag-module--rs-flex-box-grid-item-9--ChpiU";
export var rsFlexBoxGridItemOrder1 = "tag-module--rs-flex-box-grid-item-order-1--1WIyF";
export var rsFlexBoxGridItemOrder10 = "tag-module--rs-flex-box-grid-item-order-10--f0fro";
export var rsFlexBoxGridItemOrder11 = "tag-module--rs-flex-box-grid-item-order-11--l9ybw";
export var rsFlexBoxGridItemOrder12 = "tag-module--rs-flex-box-grid-item-order-12--oE8kN";
export var rsFlexBoxGridItemOrder13 = "tag-module--rs-flex-box-grid-item-order-13--sV2m-";
export var rsFlexBoxGridItemOrder14 = "tag-module--rs-flex-box-grid-item-order-14--xYALG";
export var rsFlexBoxGridItemOrder15 = "tag-module--rs-flex-box-grid-item-order-15--MOfyk";
export var rsFlexBoxGridItemOrder16 = "tag-module--rs-flex-box-grid-item-order-16--QXurx";
export var rsFlexBoxGridItemOrder17 = "tag-module--rs-flex-box-grid-item-order-17--l8eif";
export var rsFlexBoxGridItemOrder18 = "tag-module--rs-flex-box-grid-item-order-18--ZRDRu";
export var rsFlexBoxGridItemOrder19 = "tag-module--rs-flex-box-grid-item-order-19--2tpMz";
export var rsFlexBoxGridItemOrder2 = "tag-module--rs-flex-box-grid-item-order-2--da4OV";
export var rsFlexBoxGridItemOrder20 = "tag-module--rs-flex-box-grid-item-order-20--FU5u7";
export var rsFlexBoxGridItemOrder21 = "tag-module--rs-flex-box-grid-item-order-21--UFoRc";
export var rsFlexBoxGridItemOrder22 = "tag-module--rs-flex-box-grid-item-order-22--ynq2o";
export var rsFlexBoxGridItemOrder23 = "tag-module--rs-flex-box-grid-item-order-23--CG9xy";
export var rsFlexBoxGridItemOrder24 = "tag-module--rs-flex-box-grid-item-order-24--lvCU3";
export var rsFlexBoxGridItemOrder3 = "tag-module--rs-flex-box-grid-item-order-3--jNqlB";
export var rsFlexBoxGridItemOrder4 = "tag-module--rs-flex-box-grid-item-order-4--BSJXE";
export var rsFlexBoxGridItemOrder5 = "tag-module--rs-flex-box-grid-item-order-5--NTPmp";
export var rsFlexBoxGridItemOrder6 = "tag-module--rs-flex-box-grid-item-order-6--G9qcs";
export var rsFlexBoxGridItemOrder7 = "tag-module--rs-flex-box-grid-item-order-7--g3AGT";
export var rsFlexBoxGridItemOrder8 = "tag-module--rs-flex-box-grid-item-order-8--kYumj";
export var rsFlexBoxGridItemOrder9 = "tag-module--rs-flex-box-grid-item-order-9--lwimV";
export var rsTagBlue = "tag-module--rs-tag-blue--EgvR+";
export var rsTagCyan = "tag-module--rs-tag-cyan--bTjyj";
export var rsTagGreen = "tag-module--rs-tag-green--sbPf8";
export var rsTagOrange = "tag-module--rs-tag-orange--fp8PJ";
export var rsTagRed = "tag-module--rs-tag-red--5M7II";
export var rsTagViolet = "tag-module--rs-tag-violet--oddNe";
export var rsTagYellow = "tag-module--rs-tag-yellow--FPtIJ";
export var rsThemeDark = "tag-module--rs-theme-dark--UIxhH";
export var rsThemeHighContrast = "tag-module--rs-theme-high-contrast--vBeKN";
export var tag = "tag-module--tag--g9N3Z";
export var volcano = "tag-module--volcano--elMFW";