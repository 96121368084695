// extracted by mini-css-extract-plugin
export var dateHolder = "researchCard-module--dateHolder--qG4tm";
export var divider = "researchCard-module--divider--qnczp";
export var mrTp20 = "researchCard-module--mrTp20--rcmXB";
export var postCardImg = "researchCard-module--postCardImg--84XzE";
export var researchCard = "researchCard-module--researchCard---CDZ4";
export var rsBadgeBlue = "researchCard-module--rs-badge-blue--1wGSk";
export var rsBadgeContent = "researchCard-module--rs-badge-content--hgtur";
export var rsBadgeCyan = "researchCard-module--rs-badge-cyan--Ooy0d";
export var rsBadgeGreen = "researchCard-module--rs-badge-green--QfhPl";
export var rsBadgeIndependent = "researchCard-module--rs-badge-independent--Hecjl";
export var rsBadgeOrange = "researchCard-module--rs-badge-orange--I1WqT";
export var rsBadgeRed = "researchCard-module--rs-badge-red--niFdG";
export var rsBadgeViolet = "researchCard-module--rs-badge-violet--vQO+o";
export var rsBadgeYellow = "researchCard-module--rs-badge-yellow--U51Ez";
export var rsBtnBlue = "researchCard-module--rs-btn-blue--wVcjK";
export var rsBtnCyan = "researchCard-module--rs-btn-cyan--1lVMv";
export var rsBtnGreen = "researchCard-module--rs-btn-green--cZM7t";
export var rsBtnOrange = "researchCard-module--rs-btn-orange--LZwwR";
export var rsBtnRed = "researchCard-module--rs-btn-red--jxSN1";
export var rsBtnViolet = "researchCard-module--rs-btn-violet--EeuOP";
export var rsBtnYellow = "researchCard-module--rs-btn-yellow--H9SBK";
export var rsFlexBoxGridItem1 = "researchCard-module--rs-flex-box-grid-item-1--zGhuY";
export var rsFlexBoxGridItem10 = "researchCard-module--rs-flex-box-grid-item-10--oex6Y";
export var rsFlexBoxGridItem11 = "researchCard-module--rs-flex-box-grid-item-11--sGzeU";
export var rsFlexBoxGridItem12 = "researchCard-module--rs-flex-box-grid-item-12--ycaev";
export var rsFlexBoxGridItem13 = "researchCard-module--rs-flex-box-grid-item-13--Ci3Nq";
export var rsFlexBoxGridItem14 = "researchCard-module--rs-flex-box-grid-item-14--kJG0v";
export var rsFlexBoxGridItem15 = "researchCard-module--rs-flex-box-grid-item-15--l-Tj2";
export var rsFlexBoxGridItem16 = "researchCard-module--rs-flex-box-grid-item-16--el4T7";
export var rsFlexBoxGridItem17 = "researchCard-module--rs-flex-box-grid-item-17--DBbkn";
export var rsFlexBoxGridItem18 = "researchCard-module--rs-flex-box-grid-item-18--xetC-";
export var rsFlexBoxGridItem19 = "researchCard-module--rs-flex-box-grid-item-19--qIDxy";
export var rsFlexBoxGridItem2 = "researchCard-module--rs-flex-box-grid-item-2--onoBz";
export var rsFlexBoxGridItem20 = "researchCard-module--rs-flex-box-grid-item-20--gIGsD";
export var rsFlexBoxGridItem21 = "researchCard-module--rs-flex-box-grid-item-21--uqxTn";
export var rsFlexBoxGridItem22 = "researchCard-module--rs-flex-box-grid-item-22--rGyRX";
export var rsFlexBoxGridItem23 = "researchCard-module--rs-flex-box-grid-item-23--MleDh";
export var rsFlexBoxGridItem24 = "researchCard-module--rs-flex-box-grid-item-24--01Ef5";
export var rsFlexBoxGridItem3 = "researchCard-module--rs-flex-box-grid-item-3--FRv8E";
export var rsFlexBoxGridItem4 = "researchCard-module--rs-flex-box-grid-item-4--EwUgc";
export var rsFlexBoxGridItem5 = "researchCard-module--rs-flex-box-grid-item-5--BI9+B";
export var rsFlexBoxGridItem6 = "researchCard-module--rs-flex-box-grid-item-6--Oa7RM";
export var rsFlexBoxGridItem7 = "researchCard-module--rs-flex-box-grid-item-7--qAi3T";
export var rsFlexBoxGridItem8 = "researchCard-module--rs-flex-box-grid-item-8--8QMFK";
export var rsFlexBoxGridItem9 = "researchCard-module--rs-flex-box-grid-item-9--MIGWP";
export var rsFlexBoxGridItemOrder1 = "researchCard-module--rs-flex-box-grid-item-order-1--Si+fd";
export var rsFlexBoxGridItemOrder10 = "researchCard-module--rs-flex-box-grid-item-order-10--IrZGS";
export var rsFlexBoxGridItemOrder11 = "researchCard-module--rs-flex-box-grid-item-order-11--mpreO";
export var rsFlexBoxGridItemOrder12 = "researchCard-module--rs-flex-box-grid-item-order-12--pIlYR";
export var rsFlexBoxGridItemOrder13 = "researchCard-module--rs-flex-box-grid-item-order-13--l6QiB";
export var rsFlexBoxGridItemOrder14 = "researchCard-module--rs-flex-box-grid-item-order-14---yp+Z";
export var rsFlexBoxGridItemOrder15 = "researchCard-module--rs-flex-box-grid-item-order-15--MfmOM";
export var rsFlexBoxGridItemOrder16 = "researchCard-module--rs-flex-box-grid-item-order-16--D7nDD";
export var rsFlexBoxGridItemOrder17 = "researchCard-module--rs-flex-box-grid-item-order-17--fTJWe";
export var rsFlexBoxGridItemOrder18 = "researchCard-module--rs-flex-box-grid-item-order-18--Z1vZj";
export var rsFlexBoxGridItemOrder19 = "researchCard-module--rs-flex-box-grid-item-order-19--cCD+o";
export var rsFlexBoxGridItemOrder2 = "researchCard-module--rs-flex-box-grid-item-order-2--Naf+4";
export var rsFlexBoxGridItemOrder20 = "researchCard-module--rs-flex-box-grid-item-order-20--szhpV";
export var rsFlexBoxGridItemOrder21 = "researchCard-module--rs-flex-box-grid-item-order-21--Y85BO";
export var rsFlexBoxGridItemOrder22 = "researchCard-module--rs-flex-box-grid-item-order-22--Dl6LP";
export var rsFlexBoxGridItemOrder23 = "researchCard-module--rs-flex-box-grid-item-order-23--GZWup";
export var rsFlexBoxGridItemOrder24 = "researchCard-module--rs-flex-box-grid-item-order-24--l7TMH";
export var rsFlexBoxGridItemOrder3 = "researchCard-module--rs-flex-box-grid-item-order-3--+nPtY";
export var rsFlexBoxGridItemOrder4 = "researchCard-module--rs-flex-box-grid-item-order-4--X9atC";
export var rsFlexBoxGridItemOrder5 = "researchCard-module--rs-flex-box-grid-item-order-5--YE5EN";
export var rsFlexBoxGridItemOrder6 = "researchCard-module--rs-flex-box-grid-item-order-6--91B0t";
export var rsFlexBoxGridItemOrder7 = "researchCard-module--rs-flex-box-grid-item-order-7--9z78D";
export var rsFlexBoxGridItemOrder8 = "researchCard-module--rs-flex-box-grid-item-order-8--FJkUW";
export var rsFlexBoxGridItemOrder9 = "researchCard-module--rs-flex-box-grid-item-order-9--TzBMY";
export var rsTagBlue = "researchCard-module--rs-tag-blue--1EZzF";
export var rsTagCyan = "researchCard-module--rs-tag-cyan--YawET";
export var rsTagGreen = "researchCard-module--rs-tag-green--LnNid";
export var rsTagOrange = "researchCard-module--rs-tag-orange--od-32";
export var rsTagRed = "researchCard-module--rs-tag-red--Ajn1k";
export var rsTagViolet = "researchCard-module--rs-tag-violet--AIEqj";
export var rsTagYellow = "researchCard-module--rs-tag-yellow--wR5VD";
export var rsThemeDark = "researchCard-module--rs-theme-dark--0R4Ri";
export var rsThemeHighContrast = "researchCard-module--rs-theme-high-contrast--uCyyp";
export var title = "researchCard-module--title--feKff";